<template>
  <div class="video-monitor" :style="'background-image: url(' + contentBg + ');background-size: cover'">
    <div class="video-monitor-title flex">
      <span class="flex" style="cursor: pointer;" @click="$router.go(-1)">
        <img src="@/static/img/monitor/back.png" alt="">
        <span class="marginLeft12">视频监控</span>
      </span>
    </div>
    <div class="action-container text-left flex align-center">
      <div style="margin-right: 20px;" class="side-action paddingTop15 flex"></div>
      <div class="video-container-outer paddingLeft20 flex justify-between">
        <div class="flex align-center">
          <div class=" action-button-item flex justify-center align-center" :class="{ 'selected': isDirectSeeding }"
            @click="startDirectSeeding">直播</div>
          <div class="action-button-item marginLeft20 flex justify-center align-center"
            :class="{ 'selected': isPlayBack }" @click="clickToReplay">回放</div>
          <!--<div v-if="isDirectSeeding" class="marginLeft20">-->
          <!--  <span>人数统计：</span>-->
          <!--  <span style="color: #F5E74F;">98</span>-->
          <!--</div>-->
          <!--回放日期-->
          <div v-if="isPlayBack" class="marginLeft20 flex align-center">
            <div class="fontSize14" style="color: #B4C0CC;">回放日期</div>
            <el-date-picker style="margin-left: 8px;"
                            v-model="playbackDate"
                            :teleported="false"
                            type="datetimerange"
                            popper-class="custom-date-picker"
                            start-placeholder="请选择开始日期"
                            end-placeholder="请选择结束日期"
                            size="default"
                            value-format="YYYY-MM-DD HH:mm:ss"
                            @change="playbackCameraHandler"/>
          </div>
          <div v-if="isPlayBack && canSaveBackVideo" class="marginLeft20 flex align-center">
            <button class="resetBtn" style="cursor: pointer;" @click="savePlayback">保存</button>
          </div>
        </div>
        <div v-if="isDirectSeeding" class="flex align-center">
          <div class="side-action flex" style="width: auto;">
            <div v-for="iter in displayType" :key="iter.value" @click="selectDisplayType(iter)"
              class="image-box marginLeft10 flex justify-center align-center"
              :class="{ 'selected-icon': iter.value === selectDisplayTypeVal }">
              <img :src="iter.icon" alt="">
            </div>
          </div>
          <div class="play-action side-action flex" style="width: auto;">
            <div v-show="!playing" class="image-box marginLeft10 flex justify-center align-center" @click="bannerSeting">
              <img src="@/static/img/monitor/suspend.png" alt="">
            </div>
            <div v-show="playing" class="image-box marginLeft10 flex justify-center align-center">
              <img src="@/static/img/monitor/playing.png" alt="" @click="stopCarousel">
            </div>
            <div @click="fullScreenPalaceGrid('screenPalaceGrid')"
                 class="image-box marginLeft10 flex justify-center align-center">
              <img src="@/static/img/monitor/videoFullScreen.png" alt="">
            </div>
          </div>
        </div>
        <div v-else class="flex align-center">
          <div></div>
          <div class="play-action side-action flex" style="width: auto;">
            <div @click="fullScreenPalaceGrid('playbackScreenPalaceGrid')"
                 class="image-box marginLeft10 flex justify-center align-center">
              <img src="@/static/img/monitor/videoFullScreen.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="side-container">
      <div class="side-searcher">
        <el-input v-model="camera_keyword" placeholder="搜索">
          <template #prefix>
            <el-icon class="el-input__icon">
              <search />
            </el-icon>
          </template>
        </el-input>
      </div>
      <el-scrollbar class="side-camera-tree-wrap">
        <el-tree :data="camera_tree"
                 :props="{children: 'children', label: 'label', class: 'camera-tree'}"
                 node-key="id"
                 highlight-current
                 :render-after-expand="false"
                 ref="elTreeRef"
                 :filter-node-method="filterNode"
                 :default-checked-keys="playerListId"
                 @node-click="nodeClickHandler">
          <template #default="{ node, data }">
            <span class="custom-tree-node" :class="{ 'playing-tree-node': playerListId.includes(data.id) }">
              <span>{{ node.label }}</span>
            </span>
          </template>
        </el-tree>
      </el-scrollbar>
    </div>
    <!--
        1屏
        4屏 screen-4
        6屏 screen-6
        9屏 screen-9
      -->
    <div v-show="isDirectSeeding" v-loading="playerListLoading"
         :element-loading-spinner="svg"
         element-loading-svg-view-box="-10, -10, 50, 50"
         element-loading-background="rgba(122, 122, 122, 0.8)"
         class="video-content-container" :class="'screen-'+selectDisplayTypeVal"
         id="screenPalaceGrid">
      <div v-for="iter in playerList" :key="iter.id" class="video-container-item">
        <div :id="iter.elementId" style="width: 100%;height: 100%;"></div>
      </div>
      <div v-for="iter in fillingMonitor" :key="iter" class="video-container-item"></div>
    </div>
    <!-- 回放 -->
    <div v-show="isPlayBack" id="playbackScreenPalaceGrid" class="video-content-container">
      <div v-show="!playbackDate || !playbackCamera" class="empty-state flex justify-center align-center">
        <div>
          <img src="@/static/img/monitor/nullMonitor.png" alt="">
          <div class="paddingTop20">请选择回放日期和摄像头</div>
        </div>
      </div>
      <div v-show="playbackDate && playbackCamera" class="play-back-container">
        <div :id="playbackPlayer.elementId" style="width: 100%;height: 97%;"></div>
        <div class="progress-bar-outer">
          <el-slider v-model="progressValue.value"
                     :format-tooltip="formatTooltip"
                     :min="progressValue.start"
                     :max="progressValue.max"
                     size="small"
                     @change="changeStart"/>
        </div>
      </div>
    </div>
  </div>
  <!-- 截图查看 -->
  <screenshot-show v-if="screenshotImage"
                   :src="screenshotImage"
                   :save-image-obj="saveImageObj"
                   @close="screenshotImage = ''"/>
  <!-- 轮播设置 -->
  <banner-seting v-if="bannerSetingVisible"
                 @open="startCarousel"
                 @close="bannerSetingEvent"></banner-seting>
</template>

<script>
import ScreenshotShow from "@/view/visualization/components/ScreenshotShow";
import bannerSeting from "@/view/visualization/components/bannerSeting.vue"
import {getAllBuilding, monitorExistence} from "@/api/monitor";
import monitorStore, {FlvPlayer, PlaybackFlvPlayer, VideotapeTask} from "@/utils/monitor";
import {blobToFile, fileToDataURL} from "@/utils/common";
import dayjs from "dayjs";
import screenfull from 'screenfull';

export default {
  name: 'videoMonitor',
  components: { ScreenshotShow, bannerSeting },
  inject: {
    indexVoiceInput: {
      default: ''
    },
  },
  data() {
    return {
      // -
      // - i
      displayType: [
        { label: '单屏', value: '1', icon: require('@/static/img/monitor/screen-1.png'), },
        { label: '四屏', value: '4', icon: require('@/static/img/monitor/screen-4.png'), },
        { label: '六屏', value: '6', icon: require('@/static/img/monitor/screen-6.png'), },
        { label: '九屏', value: '9', icon: require('@/static/img/monitor/screen-9.png'), },
      ],
      // 默认显示单屏
      selectDisplayTypeVal: '1',
      // 播放模式
      currPlayMode: 'DIRECT_SEEDING',
      // 回放摄像头
      playbackCamera: null,
      // 回放的播放器
      playbackPlayer: {},
      playMode: [
        { label: '直播', value: 'DIRECT_SEEDING', selected: true },
        { label: '回放', value: 'PLAYBACK', selected: false },
      ],
      // 回放日期
      playbackDate: '',

      // -- require
      contentBg: require('@/static/img/monitor/vodeoContainerBg.png'),
      // --
      camera_keyword: '',
      camera_tree: [],
      // 摄像头列表
      cameraList: [],
      // 监控列表
      playerList: [],
      bannerSetingVisible:false,
      fillingMonitor: 9,
      playerListLoading: false,
      svg: `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `,

      // 轮播设置
      // 轮播是否开启
      playing: false,
      playingTimer: null,
      prevIndex: 0,
      //
      screenshotImage: '',
      saveImageObj: {},
      //
      findPrevNode: null,
      // 当前正在录像
      currVideoCoverEvent: new VideotapeTask(),
      //
      canSaveBackVideo: false,
      //
      progressValue: {
        max: 0,
        value: 0,
        start: 0,
        end: 0,
      }
    }
  },
  computed: {
    // -i
    isDirectSeeding() {
      return this.currPlayMode === 'DIRECT_SEEDING'
    },
    isPlayBack() {
      return this.currPlayMode === 'PLAYBACK'
    },
    /**
     * 正在播放的id
     */
    playerListId(){
      const ids = []
      this.playerList.forEach(iter => ids.push(iter.id))
      if (Reflect.has(this.playbackPlayer, 'id')) {
        ids.push(this.playbackPlayer.id)
      }
      return ids
    }
  },
  watch: {
    // 搜索
    'camera_keyword': {
      handler(val){
        this.$nextTick(() => {
          this.$refs.elTreeRef.filter(val)
        })
      }
    },
    // 语音助手监听
    'indexVoiceInput.value': {
      handler(val){
        this.$nextTick(() => {
          this.camera_keyword = val
        })
      }
    },
    // 分屏控制摄像头数量
    'selectDisplayTypeVal': {
      handler(val){
        const count = parseInt(val)
        // -
        const iterList = []
        for (let i = 0; i < this.playerList.length; i++) {
          if (i >= count) {
            // 获取预销毁的
            iterList.push(this.playerList[i])
          }
        }
        // 销毁
        iterList.forEach(iter => {
          this.destroyTheCamera(iter)
        })

        // 测栏重置
        const idArr = []
        this.playerList.forEach(iter => idArr.push(iter.id))
        this.$refs.elTreeRef.setCheckedKeys(idArr)
      }
    }
  },
  mounted() {
    this.getAllBuildingData()
    // 语音助手搜索
    const keyword = this.$route.query.keyword
    if (keyword) {
      this.keywordHandler = () => this.camera_keyword = keyword
    }
  },
  /**
   * 销毁
   */
  beforeUnmount() {
    // 销毁直播
    this.destroyTheCameraAll()
    // 销毁回放
    this.destroyPlayback()
  },
  methods: {
    /**
     * 宫格全屏
     */
    fullScreenPalaceGrid(id='screenPalaceGrid'){
      this.$nextTick(() => {
        // 指定元素进入全屏模式
        const element = document.getElementById(id);
        if (!screenfull.enabled) {
          this.$message.info('您的浏览器不支持全屏');
          return;
        }
        // 开启全屏
        screenfull.request(element);
        // 退出全屏
        this.quitFullScreen = () => {
          screenfull.toggle()
        }
      })
    },
    /**
     * 保存回放
     */
    savePlayback(){
      if (this.playbackDate.length === 0) {
        this.$message.error('请选择回放日期')
        return
      }
      if (!this.playbackCamera) {
        this.$message.error('请选择摄像头')
        return
      }

      const start = dayjs(this.playbackDate[0])
      const end = dayjs(this.playbackDate[1])
      const diff = end.diff(start, 'minute')
      if (diff > 30) {
        this.$message.error('最长只能保存30分钟的视频')
        return
      }

      this.playbackPlayer.manualScreenshot(img => {
        fileToDataURL(img, url => {
          monitorStore.setShowImgBase64(url)
          monitorStore.setCurrVideoCoverEvent({
            imgBase64: url,
            imgFile: blobToFile(img),
            startTime: dayjs(this.playbackDate[0]).format('YYYY-MM-DD HH:mm:ss'),
            endTime: dayjs(this.playbackDate[1]).format('YYYY-MM-DD HH:mm:ss'),
            equipmentId: this.playbackPlayer.id
          })
        })
      })
    },
    /**
     * 摄像头搜索
     * @param value
     * @param data
     * @returns {boolean|*}
     */
    filterNode(value, data){
      if (!value) return true
      // 检测是否是上一个结点的子节点
      if (this.findPrevNode) {
        // 检测上一个结点是否符合规则
        const res = this.findPrevNode.label.includes(value)
        // 如果当前结点是上一个结点的子节点, 那就子结点全部通过
        if (res && this.findPrevNode.id === data.parentId) {
          return true
        }
      }
      this.findPrevNode = data
      return data.label.includes(value)
    },

    /**
     * 楼栋列表
     */
    getAllBuildingData(){
      getAllBuilding().then(res => {
        const camera_tree = res.data
        // 楼
        camera_tree.map(floor => {
          floor.label = floor.name
          floor.children = []

          // 整合实验室摄像头到一级
          floor.laboratoryList.map(laboratory => {
            laboratory.label = laboratory.name
            laboratory.children = laboratory.equipmentList
            // 摄像头
            laboratory.children.map(camera => {
              camera.label = camera.name
              camera.children = []
              camera.parentId = laboratory.id
              // 记录摄像头
              this.cameraList.push(camera)
              //
              floor.children.push(camera)
            })
          })
        })
        this.camera_tree = camera_tree
        // 记录摄像头索引处理 添加循环链表指向
        this.cameraList.map((iter, index) => {
          iter.nextIndex = index + 1
        })
        if (this.cameraList.length > 0) {
          this.cameraList[this.cameraList.length-1].nextIndex = 0
        }
        // 关键字筛选
        Reflect.has(this,'keywordHandler') && this.keywordHandler()
      })
    },

    /**
     * 播放摄像头
     */
    playCamera(data){
      // console.log('播放摄像头: ', data.name)
      const currCount = parseInt(this.selectDisplayTypeVal)
      if (currCount > 1 && ((this.playerList.length + 1) > currCount)) {
        this.$message.warning('可同时播放数已满, 请先关闭部分摄像头!')
        return
      }

      // 播放对象是否存在
      const item = this.playerList.find(iter => iter.id === data.id)
      if (!item) {
        // 生成播放对象
        const iter = new FlvPlayer(data.id, `direct-video-${data.id}`)
        // 监听全屏事件
        iter.setFullscreenHandler(() => {
          // 开启全屏处理 除自己以外全部暂停
          this.playerList.forEach(curr => {
            if (curr.id !== data.id) {
              curr.suspend()
            }
          })
        }, () => {
          // 关闭全屏处理 全部恢复播放
          this.playerList.forEach(curr => curr.play() )
        })
        // 截屏事件处理
        iter.screenshot((blob, obj) => {
          this.saveImageObj = obj
          //
          fileToDataURL(blob, (url) => {
            this.screenshotImage = url
            Reflect.has(this, 'quitFullScreen') && this.quitFullScreen()
          })
        })
        // 追加右键菜单 关闭->销毁关闭当前
        iter.appendContextmenu({
          text: '关闭',
          click: () => this.destroyTheCamera(iter)
        })
        // 录像事件
        iter.appendContextmenu({
          text: '开始录像',
          click: () => {
            Reflect.has(iter, 'showRecording') && iter.showRecording()
            iter.manualScreenshot(img => {
              fileToDataURL(img, url => {
                this.currVideoCoverEvent.setTask({
                  id: iter.id,
                  value: {
                    imgBase64: url,
                    imgFile: blobToFile(img),
                    startTime: dayjs(new Date).format('YYYY-MM-DD HH:mm:ss'),
                    endTime: '',
                    equipmentId: iter.id
                  }
                })
              })
            })
          }
        })
        iter.appendContextmenu({
          text: '结束录像',
          click: () => {
            Reflect.has(iter, 'showRecording') && iter.hideRecording()
            // 确保正在录像中
            if (this.currVideoCoverEvent.hasTask()) {
              const item = this.currVideoCoverEvent.getTask(iter.id)
              if (item) {
                //
                monitorStore.setShowImgBase64(item.value.imgBase64)
                // 更新结束时间
                item.value.endTime = dayjs(new Date).format('YYYY-MM-DD HH:mm:ss')
                //
                monitorStore.setCurrVideoCoverEvent(item.value)
              }
            }
          }
        })
        // 单屏替换
        if (this.playerList.length === 1 && currCount === 1) {
          // 销毁第一项
          this.playerList[0].destruction()
          // 移除第一项
          this.playerList.shift()
        }
        // 追加到播放列表
        this.playerList.push(iter)
        // 播放
        this.$nextTick(() => {
          iter.play()
        })
      } else {
        // 播放
        this.$nextTick(() => {
          item.play()
        })
      }
    },

    /**
     * 销毁摄像头
     */
    destroyTheCamera(data){
      const index = this.playerList.findIndex(iter => iter.id === data.id)
      if (index !== -1) {
        this.playerList[index].destruction()
        this.playerList.splice(index, 1)
      }
    },

    /**
     * 销毁所有摄像头播放 直播
     */
    destroyTheCameraAll(){
      this.playerList.forEach(iter => iter.destruction())
      this.playerList = []
    },

    /**
     * 销毁回放
     */
    destroyPlayback(){
      if (this.playbackPlayer.flvPlayer) {
        this.playbackPlayer.destruction()
        this.playbackPlayer = {}
      }
    },

    /**
     * 空白镜头填充
     */
    blankLensFilling(){
      // 自动切换显示模式
      // this.selectDisplayTypeVal
      // 1 4 6 9
      const len = this.playerList.length
      if(len === 1){
        this.selectDisplayTypeVal = '1'
      }else if (len > 1 && len <= 4) {
        this.selectDisplayTypeVal = '4'
      } else if(len > 4 && len <= 6){
        this.selectDisplayTypeVal = '6'
      } else if(len > 6 && len <= 9){
        this.selectDisplayTypeVal = '9'
      }
      // 空白填充
      this.fillingMonitor = 9 - len
    },

    /**
     * 时间格式化
     * @param val
     * @returns {string}
     */
    formatTooltip(val){
      return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
    },

    /**
     * 进度条拖放
     */
    changeStart(val){
      // 进度条不变, 重新生成视频范围
      const start = dayjs(new Date(val)).format('YYYY-MM-DD HH:mm:ss')
      const end = this.playbackDate[1]
      this.playbackCameraHandlerWithSchedule(start, end)
    },

    /**
     * 通过进度条控制时段
     * @param start
     * @param end
     */
    playbackCameraHandlerWithSchedule(start, end){
      if (this.playbackCamera && this.playbackDate) {
        // 播放回放
        const playbackHandler = () => {
          // 存在则先销毁再新建回放连接
          this.destroyPlayback()
          this.playbackPlayer = new PlaybackFlvPlayer(this.playbackCamera.id,
              'playbackCamera',
              start,
              end
          )
          this.playbackPlayer.onPlayHandler(() => {
            this.canSaveBackVideo = false
          })
          this.playbackPlayer.onProgress(() => {
            this.canSaveBackVideo = true
          })
          // 截屏事件处理
          this.playbackPlayer.screenshot((blob, obj) => {
            this.saveImageObj = obj
            //
            fileToDataURL(blob, (url) => {
              this.screenshotImage = url
              Reflect.has(this, 'quitFullScreen') && this.quitFullScreen()
            })
          })
          //
          this.$nextTick(() => this.playbackPlayer.play())
        }

        // 检测是否存在
        monitorExistence(this.playbackCamera.id, {
          startDate: start,
          endTime: end,
        }).then(res => {
          if (res.success) {
            playbackHandler()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },

    /**
     * 回放摄像头
     */
    playbackCameraHandler(){
      if (this.playbackCamera && this.playbackDate) {
        // 播放回放
        const playbackHandler = () => {
          // 存在则先销毁再新建回放连接
          this.destroyPlayback()
          this.playbackPlayer = new PlaybackFlvPlayer(this.playbackCamera.id, 'playbackCamera', this.playbackDate[0], this.playbackDate[1])
          this.playbackPlayer.onPlayHandler(() => {
            this.canSaveBackVideo = false
          })
          this.playbackPlayer.onProgress(() => {
            this.canSaveBackVideo = true
          })
          // 截屏事件处理
          this.playbackPlayer.screenshot((blob, obj) => {
            this.saveImageObj = obj
            //
            fileToDataURL(blob, (url) => {
              this.screenshotImage = url
              Reflect.has(this, 'quitFullScreen') && this.quitFullScreen()
            })
          })
          //
          this.$nextTick(() => this.playbackPlayer.play())
        }

        // 进度条重置
        const updateProgressBar = () => {
          const start = new Date(this.playbackDate[0]).getTime()
          const end = new Date(this.playbackDate[1]).getTime()
          this.progressValue.start = start
          this.progressValue.value = start
          this.progressValue.max = end
        }

        // 检测是否存在
        monitorExistence(this.playbackCamera.id, {
          startDate: this.playbackDate[0],
          endTime: this.playbackDate[1],
        }).then(res => {
          if (res.success) {
            playbackHandler()
            // 更新进度条
            updateProgressBar()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },

    // - e
    /**
     * 选择显示模式
     * @param value
     */
    selectDisplayType({ value }){
      this.selectDisplayTypeVal = value
    },

    /**
     * 点击回放按钮
     */
    clickToReplay(){
      if (this.isPlayBack) {
        return
      }
      // 如果有轮播, 则停止轮播
      this.stopCarousel()

      // 销毁直播
      this.destroyTheCameraAll()
      // 标记回放模式
      this.currPlayMode = 'PLAYBACK'
      // 清空选择
      this.$nextTick(() => {
        this.$refs.elTreeRef.setCheckedKeys([])
      })
    },

    /**
     * 点击直播
     */
    startDirectSeeding(){
      if (this.isDirectSeeding) {
        return
      }
      // 销毁回放
      this.destroyPlayback()
      this.playbackCamera = null
      // 标记直播模式
      this.currPlayMode = 'DIRECT_SEEDING'
      // 清空选择
      this.$nextTick(() => {
        this.$refs.elTreeRef.setCheckedKeys([])
      })
    },

    /**
     * 切换摄像头loading播放
     */
    loadingHandler(handler){
      // loading start
      this.playerListLoading = true

      // ...
      handler()

      // loading end
      setTimeout(() => {
        this.playerListLoading = false
      }, 500)
    },

    /**
     * 点击结点
     */
    nodeClickHandler(data){
      this.loadingHandler(() => {
        // 直播模式
        if (this.isDirectSeeding) {
          // 左侧摄像头选择 | 选中摄像头
          if (Reflect.has(data, 'ip')) {
            // 播放
            this.playCamera(data)
          }
          return
        }

        // 回放模式
        if (this.isPlayBack) {
          // 左侧摄像头取消选择 | 断开连接
          if (Reflect.has(data, 'ip')) {
            // 是否有回放 有则销毁
            this.destroyPlayback()
          }

          // 左侧摄像头选择 | 选中摄像头
          if (Reflect.has(data, 'ip')) {
            // 记录 检测回放
            this.playbackCamera = data
            this.playbackCameraHandler()
          }
        }
      })
    },

    /**
     * 开启轮播
     * @param intervalTime 间隔时间 3
     * @param carouselMode 轮播模式 随机|顺序
     */
    startCarousel({ intervalTime, carouselMode }){
      const randomIntArr = (n, m) => {
        // 结果数组
        let arr = [];

        // 当m大于n时,直接返回0~(n-1)的所有数字
        if (m > n) {
          return Array.from({length: n}, (v, i) => i);
        }

        // 生成不重复的随机数
        while(arr.length < m) {
          let rnd = Math.floor(Math.random() * n);
          if(arr.indexOf(rnd) === -1) {
            arr.push(rnd);
          }
        }
        return arr;
      }

      // 关闭轮播设置弹层
      this.bannerSetingVisible = false
      // 标记开启轮播
      this.playing = true
      // 清除原有定时器
      this.playingTimer && clearInterval(this.playingTimer)
      // 轮播逻辑
      const handler = () => {
        // -
        const currCount = parseInt(this.selectDisplayTypeVal)
        this.loadingHandler(() => {
          // 清空播放列表
          this.destroyTheCameraAll()
          // 顺序
          if (carouselMode === 1) {
            let sideKeys = []
            for (let i = 0; i < currCount; i++) {
              let firstElement = this.cameraList[this.prevIndex]
              this.playCamera(firstElement)
              this.prevIndex = firstElement.nextIndex
              //
              sideKeys.push(firstElement.id)
            }
            this.$nextTick(() => {
              this.$refs.elTreeRef.setCheckedKeys(sideKeys)
            })
            return
          }
          // 随机
          if (carouselMode === 2) {
            // 根据当前展示个数随机生成视频, 展示个数大于总数按照总数生成 轮播目标
            const rotationsCount = randomIntArr(this.cameraList.length, currCount)
            let sideKeys = []
            rotationsCount.forEach(i => {
              // 生成视频 播放
              this.playCamera(this.cameraList[i])
            })
            this.$nextTick(() => {
              this.$refs.elTreeRef.setCheckedKeys(sideKeys)
            })
          }
        })
      }
      handler()
      this.playingTimer = setInterval(handler, intervalTime * 1000 + 500)
    },

    /**
     * 停止轮播
     */
    stopCarousel(){
      // 标记开启轮播
      this.playing = false
      clearInterval(this.playingTimer)
    },

    // 轮播设置
    bannerSeting() {
      this.bannerSetingVisible = true
    },
    /**
     * 关闭设置窗口
     */
    bannerSetingEvent() {
      this.bannerSetingVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.resetBtn {
  background: rgba(0, 157, 255, 0.1);
  box-shadow: inset 0 0 4px 0 #009DFF;
  border-radius: 5px;
  border: 1px solid #009DFF;
  height: 30px;
}
.video-monitor {
  position: absolute;
  top: 112px;
  left: 24px;

  width: 1872px;
  height: 944px;

  .video-monitor-title {
    position: relative;
    left: 20px;
    top: 9px;

    span {
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      color: #009DFF;
    }
  }

  .action-container {
    position: absolute;
    left: 20px;
    top: 43px;
    height: 80px;
    width: 1832px;

    .side-action {
      width: 300px;
      box-sizing: border-box;

      .image-box {
        width: 40px;
        height: 30px;
        background: #0F2B3F;
        border-radius: 4px;

        img {
          width: 28px;
          height: 28px;
          object-fit: cover;
        }
      }

      // 选中模式
      .selected-icon {
        border: 1px solid #009DFF;
      }
    }

    .play-action {}

    .video-container-outer {
      width: 1512px;
      --el-text-color-regular: #B4C0CC;
      --el-fill-color-blank: rgba(0, 0, 0, 0);
      --el-border-color: #009DFF;
      --el-border-color-hover: #009DFF;
      --el-select-input-focus-border-color: #009DFF;
      --el-color-primary: #009DFF;
      --el-select-border-color-hover: #009DFF;
      --el-input-focus-border-color: #009DFF;
      --el-input-hover-border-color: #009DFF;
      --el-text-color-disabled: #009DFF;
      // 下拉框背景
      --el-bg-color-overlay: #00000a;
      --el-fill-color-light: #001e38;

      .action-button-item {
        width: 88px;
        height: 40px;
        background-image: url('../../static/img/monitor/defaultActionBg.png');
        background-size: cover;

        color: #009DFF;
        cursor: pointer;
      }

      .selected {
        font-weight: bold;
        background-image: url('../../static/img/monitor/selectedActionBg.png');
      }

      .action-button-left-item {
        width: 50px;
        height: 40px;
        background: rgba(0, 157, 255, 0.1);
        box-shadow: inset 0 0 4px 0 #009DFF;
        border-radius: 5px;
        border: 1px solid #009DFF;

        img {
          width: 24px;
          height: 24px;
          object-fit: cover;
        }
      }
    }
  }

  .side-container {
    position: absolute;
    left: 20px;
    top: 74px;
    width: 300px;
    height: 850px;
    background: #0F2B3F;
    padding: 20px;
    box-sizing: border-box;

    .side-searcher {
      /deep/ .el-input {
        width: 260px;
        height: 40px;
        background: rgba(6, 12, 22, 0.6);
        border-radius: 0px;
      }

      /deep/ .el-input__wrapper {
        background: rgba(6, 12, 22, 0.6);
        border: none;
        box-shadow: none;
      }

      /deep/ .el-input__prefix,
      /deep/ .el-input__inner {
        color: #80CEFF;
      }
    }

    .side-camera-tree-wrap {
      margin-top: 20px;
      height: 700px;

      /deep/ .el-tree {
        --el-fill-color-blank: transparent;
        --el-font-size-base: 16px;
        --el-tree-node-content-height: 30px;
        --el-tree-node-hover-bg-color: transparent;
        --el-tree-text-color: #B4C0CC;
        --el-tree-expand-icon-color: #B4C0CC;
      }
      
      .playing-tree-node{
        color: #009DFF;
        &::before{
          content: '播放中';
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }
  }

  .video-content-container {
    position: absolute;
    left: 340px;
    top: 124px;
    width: 1512px;
    height: 800px;
    background: #0F2B3F;
    overflow: hidden;
    outline: #009DFF solid 1px;

    .empty-state {
      width: 100%;
      height: 100%;

      img {
        width: 124px;
        height: 124px;
        object-fit: cover;
      }
    }

    .play-back-container {
      width: 100%;
      height: 100%;
      .progress-bar-outer{
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        overflow: hidden;
      }
    }

    .video-container-item {
      width: 100%;
      height: 100%;
      outline: #009DFF solid 1px;
      overflow: hidden;
      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .screen-1{
    .video-container-item:nth-of-type(n+2){
      outline: none;
    }
  }

  .screen-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 0;

    .video-container-item:nth-of-type(1) {
      grid-area: 1 / 1 / 2 / 2;
    }
    .video-container-item:nth-of-type(2) {
      grid-area: 1 / 2 / 2 / 3;
    }
    .video-container-item:nth-of-type(3) {
      grid-area: 2 / 1 / 3 / 2;
    }
    .video-container-item:nth-of-type(4) {
      grid-area: 2 / 2 / 3 / 3;
    }
    .video-container-item:nth-of-type(n+5){
      outline: none;
    }
  }

  .screen-6 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 0;

    .video-container-item:nth-of-type(1) {
      grid-area: 1 / 1 / 3 / 3;
    }

    .video-container-item:nth-of-type(2) {
      grid-area: 1 / 3 / 2 / 4;
    }

    .video-container-item:nth-of-type(3) {
      grid-area: 2 / 3 / 3 / 4;
    }

    .video-container-item:nth-of-type(4) {
      grid-area: 3 / 1 / 4 / 2;
    }

    .video-container-item:nth-of-type(5) {
      grid-area: 3 / 2 / 4 / 3;
    }

    .video-container-item:nth-of-type(6) {
      grid-area: 3 / 3 / 4 / 4;
    }

    .video-container-item:nth-of-type(n+7){
      outline: none;
    }
  }

  .screen-9 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 0;
  }
}</style>